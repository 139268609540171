import { defu } from 'defu'
import type { FetchOptions } from 'ofetch'

const createDefaultOptions = (
  opts: FetchOptions & { [key: string]: string } = {},
) =>
  defu(opts, {
    fetch: globalThis.fetch,
    async onRequest({ options }) {
      const { $auth0 } = useNuxtApp()
      const { getAccessTokenSilently } = $auth0()

      const token = await getAccessTokenSilently()

      options.headers = defu(
        { Authorization: `Bearer ${token}` },
        options.headers,
        {},
      )
    },
  } as FetchOptions)

export const $apiFetch = $fetch.create(createDefaultOptions())

type UseFetch = typeof useFetch

export const useApiFetch: UseFetch = (url, options = {}) =>
  useFetch(url, defu(options, createDefaultOptions()))
