<script setup lang="ts">
const { $auth0, $pendo } = useNuxtApp()
const config = useRuntimeConfig().public
const route = useRoute()

const { user, logout } = $auth0()
const appPermissionRequired = [WORKSPACE_PERMISSION.readPhi]
const workspaceId = config.WORKSPACE_ID

useHead(() => ({
  title: (route.meta.title as string) || undefined,
  titleTemplate: (s) => {
    return s
      ? `${s} | Rialtic Provider Inquiries`
      : 'Rialtic Provider Inquiries'
  },
}))

const { data: userData, pending } = useApiFetch('/api/auth-user', {
  watch: [user],
})

const onLogout = async () => {
  sessionStorage.clear()
  localStorage.clear()
  await logout({
    logoutParams: {
      returnTo: `${location.origin}/`,
    },
  })
}

watch(
  user,
  (userData) => {
    if (userData?.sub) {
      const { email, sub: id, name: full_name } = userData

      const roles: string[] = userData['https://auth.rialtic.io/roles']

      $pendo.identify({
        visitor: {
          id,
          email,
          full_name,
          role: roles[0],
        },
        account: {
          id: email?.split('@')[1] || config.WORKSPACE_ID,
          rialtic_app: 'provider_inquiries',
          workspace_id: config.WORKSPACE_ID,
        },
      })
    }
  },
  {
    immediate: true,
  },
)

const userPermissions = computed(() => userData.value?.permissions || [])
const workspace = computed(() => userData.value?.workspace || {})

provide('user-permissions', userPermissions)
provide('workspace', workspace)
</script>

<template>
  <div class="font-poppins bg-surface-bg">
    <NuxtLoadingIndicator
      color="repeating-linear-gradient(to right,#6F7CCD 0%,#28B7F0 62%,#35CDDA 100%)"
    />
    <AuthPermissionsGuard
      :workspace-id="workspaceId"
      :workspace-permissions="appPermissionRequired"
    >
      <NuxtLayout>
        <NuxtPage />
      </NuxtLayout>

      <template #fallback>
        <AuthLoading
          v-if="!userData || pending"
          :message="config.auth.loginMessage"
          loading
        />
        <AuthPermissionDeniedScreen
          v-else
          title="That didn't quite work..."
          @logout="onLogout()"
        />
      </template>
    </AuthPermissionsGuard>

    <AuthLoading :message="config.auth.loginMessage" />
    <data id="workspace_id" :value="config.WORKSPACE_ID" />
  </div>
</template>
