import { default as _91claimLine_936yrYO1hElNMeta } from "/builds/rialtic/workflows/console-ui/apps/disputes/pages/[connectorId]/[claimId]/[claimLine].vue?macro=true";
import { default as indexeDc3Xb5dlpMeta } from "/builds/rialtic/workflows/console-ui/apps/disputes/pages/[connectorId]/[claimId]/index.vue?macro=true";
import { default as _91claimId_93TgBVrsEe9bMeta } from "/builds/rialtic/workflows/console-ui/apps/disputes/pages/[connectorId]/[claimId].vue?macro=true";
import { default as _91claimId_93LCEjLQpWk3Meta } from "/builds/rialtic/workflows/console-ui/apps/claim-lab/pages/[connectorId]/claim-lab/[claimId].vue?macro=true";
import { default as _91connectorId_93pPQASykoTaMeta } from "/builds/rialtic/workflows/console-ui/apps/claim-lab/pages/[connectorId].vue?macro=true";
import { default as app_45status9y6pwzVESNMeta } from "/builds/rialtic/workflows/console-ui/apps/disputes/pages/app-status.vue?macro=true";
import { default as indexAoFPxoiP1YMeta } from "/builds/rialtic/workflows/console-ui/apps/disputes/pages/index.vue?macro=true";
import { default as auth_45callbackvzPvcsneV7Meta } from "/builds/rialtic/workflows/console-ui/packages/nuxt-auth0/src/runtime/pages/auth-callback.vue?macro=true";
import { default as auth_45login2NyvXp0YlaMeta } from "/builds/rialtic/workflows/console-ui/packages/nuxt-auth0/src/runtime/pages/auth-login.vue?macro=true";
import { default as auth_45statustPEZIB9azrMeta } from "/builds/rialtic/workflows/console-ui/packages/nuxt-auth0/src/runtime/pages/auth-status.vue?macro=true";
export default [
  {
    name: _91connectorId_93pPQASykoTaMeta?.name ?? "connectorId",
    path: _91connectorId_93pPQASykoTaMeta?.path ?? "/:connectorId()",
    meta: _91connectorId_93pPQASykoTaMeta || {},
    alias: _91connectorId_93pPQASykoTaMeta?.alias || [],
    redirect: _91connectorId_93pPQASykoTaMeta?.redirect,
    component: () => import("/builds/rialtic/workflows/console-ui/apps/claim-lab/pages/[connectorId].vue").then(m => m.default || m),
    children: [
  {
    name: _91claimId_93TgBVrsEe9bMeta?.name ?? undefined,
    path: _91claimId_93TgBVrsEe9bMeta?.path ?? ":claimId()",
    meta: _91claimId_93TgBVrsEe9bMeta || {},
    alias: _91claimId_93TgBVrsEe9bMeta?.alias || [],
    redirect: _91claimId_93TgBVrsEe9bMeta?.redirect,
    component: () => import("/builds/rialtic/workflows/console-ui/apps/disputes/pages/[connectorId]/[claimId].vue").then(m => m.default || m),
    children: [
  {
    name: _91claimLine_936yrYO1hElNMeta?.name ?? "connectorId-claimId-claimLine",
    path: _91claimLine_936yrYO1hElNMeta?.path ?? ":claimLine()",
    meta: _91claimLine_936yrYO1hElNMeta || {},
    alias: _91claimLine_936yrYO1hElNMeta?.alias || [],
    redirect: _91claimLine_936yrYO1hElNMeta?.redirect,
    component: () => import("/builds/rialtic/workflows/console-ui/apps/disputes/pages/[connectorId]/[claimId]/[claimLine].vue").then(m => m.default || m)
  },
  {
    name: indexeDc3Xb5dlpMeta?.name ?? "connectorId-claimId",
    path: indexeDc3Xb5dlpMeta?.path ?? "",
    meta: indexeDc3Xb5dlpMeta || {},
    alias: indexeDc3Xb5dlpMeta?.alias || [],
    redirect: indexeDc3Xb5dlpMeta?.redirect,
    component: () => import("/builds/rialtic/workflows/console-ui/apps/disputes/pages/[connectorId]/[claimId]/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: _91claimId_93LCEjLQpWk3Meta?.name ?? "connectorId-claim-lab-claimId",
    path: _91claimId_93LCEjLQpWk3Meta?.path ?? "claim-lab/:claimId()",
    meta: _91claimId_93LCEjLQpWk3Meta || {},
    alias: _91claimId_93LCEjLQpWk3Meta?.alias || [],
    redirect: _91claimId_93LCEjLQpWk3Meta?.redirect,
    component: () => import("/builds/rialtic/workflows/console-ui/apps/claim-lab/pages/[connectorId]/claim-lab/[claimId].vue").then(m => m.default || m)
  }
]
  },
  {
    name: app_45status9y6pwzVESNMeta?.name ?? "app-status",
    path: app_45status9y6pwzVESNMeta?.path ?? "/app-status",
    meta: app_45status9y6pwzVESNMeta || {},
    alias: app_45status9y6pwzVESNMeta?.alias || [],
    redirect: app_45status9y6pwzVESNMeta?.redirect,
    component: () => import("/builds/rialtic/workflows/console-ui/apps/disputes/pages/app-status.vue").then(m => m.default || m)
  },
  {
    name: indexAoFPxoiP1YMeta?.name ?? "index",
    path: indexAoFPxoiP1YMeta?.path ?? "/",
    meta: indexAoFPxoiP1YMeta || {},
    alias: indexAoFPxoiP1YMeta?.alias || [],
    redirect: indexAoFPxoiP1YMeta?.redirect,
    component: () => import("/builds/rialtic/workflows/console-ui/apps/disputes/pages/index.vue").then(m => m.default || m)
  },
  {
    name: auth_45callbackvzPvcsneV7Meta?.name ?? "auth-callback",
    path: auth_45callbackvzPvcsneV7Meta?.path ?? "/auth/callback",
    meta: auth_45callbackvzPvcsneV7Meta || {},
    alias: auth_45callbackvzPvcsneV7Meta?.alias || [],
    redirect: auth_45callbackvzPvcsneV7Meta?.redirect,
    component: () => import("/builds/rialtic/workflows/console-ui/packages/nuxt-auth0/src/runtime/pages/auth-callback.vue").then(m => m.default || m)
  },
  {
    name: auth_45login2NyvXp0YlaMeta?.name ?? "auth-login",
    path: auth_45login2NyvXp0YlaMeta?.path ?? "/auth/login",
    meta: auth_45login2NyvXp0YlaMeta || {},
    alias: auth_45login2NyvXp0YlaMeta?.alias || [],
    redirect: auth_45login2NyvXp0YlaMeta?.redirect,
    component: () => import("/builds/rialtic/workflows/console-ui/packages/nuxt-auth0/src/runtime/pages/auth-login.vue").then(m => m.default || m)
  },
  {
    name: auth_45statustPEZIB9azrMeta?.name ?? "auth-status",
    path: auth_45statustPEZIB9azrMeta?.path ?? "/auth/status",
    meta: auth_45statustPEZIB9azrMeta || {},
    alias: auth_45statustPEZIB9azrMeta?.alias || [],
    redirect: auth_45statustPEZIB9azrMeta?.redirect,
    component: () => import("/builds/rialtic/workflows/console-ui/packages/nuxt-auth0/src/runtime/pages/auth-status.vue").then(m => m.default || m)
  }
]